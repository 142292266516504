@tailwind base;
@tailwind components;
@tailwind utilities;

.topHeaderbar {
  background-color: #0c375bef !important;
  transition: 0.5s;
  height: 100% !important;
}

.navbar-toggler {
  /* width: 60px;
  height:60px; */
  position: relative;
  transition: 0.5 ease-in-out;
  border: 2px solid black;
  border-radius: 13px;
  z-index: 9999;
  padding: 4px 6px;
  width: 46px;
  height: 42px;
  margin-bottom: 84px;
  margin-right: 18px;
}

/* .navbar-toggler span {
   margin:0;
   padding: 0;
} */

.toggler-icon {
  display: block;
  position: absolute;
  height: 2px;
  /* width :100%; */
  background: whitesmoke;
  border-radius: 1px;
  border: none;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  border: 2px solid whitesmoke;
  outline: none;
  box-shadow: none;
}

.navbar-toggler .top-bar {
  opacity: 1;
  /* margin-bottom: 20px !important; */
  width: 30px;
  transform: rotate(135deg);
}

.middle-bar {
  margin-top: 0px;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  /* margin-bottom: 20px !important; */
  /* margin-top: 20px !important; */
  width: 24px;
  filter: alpha(opacity=0);
}
.navbar-toggler .bottom-bar {
  opacity: 1;
  /* margin-bottom: 20px !important; */
  margin-top: 0px;
  width: 30px;
  transform: rotate(-135deg);
}

.collapsed {
  margin-top: -20px !important;
  transform: rotate(0deg) !important;
  border: 0;
}

.collapsed {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.collapsed {
  margin-top: 20px !important;
  transform: rotate(0deg) !important;
}

.close_button {
  /* color: 2px solid rgb(240, 176, 24) !important; */
  /* background-color: azure; */
  /* border: 2px solid rgb(240, 176, 24); */
  border-radius: 5px;
  margin-right: 1px !important;
  margin-top: 10px !important;
  width: 35px !important;
  height: 35px !important;
}

.menuStyle {
  color: rgb(240, 176, 24);
  margin-top: 80px;
  font-size: 35px;
  font-weight: 900;
  text-transform: uppercase;
}
.menuStyle ul li {
  list-style: none;
  text-align: left;
}
.linkStyles {
  text-decoration: none;
  color: #a1a7b4;
  font-weight: 500;

  /* color: rgb(240, 176, 24); */
}
.linkStyles:hover {
  text-decoration: none;
  color: rgb(226, 226, 226);
}

.collapseneuron {
  background: url("./Images/Collapsable\ Menu_neurons_image.png") no-repeat;
  background-position: bottom;
  background-size: cover;
  position: absolute;
  top: 5%;
  height: 288px;
  width: 275px;
}
