@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&family=Rubik:wght@300;500;600;700;800;900&display=swap");

@font-face {
  font-family: "BrushKing";
  src: url("./Fonts/FontsFree-Net-Brush-King.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --black: #242d49;
  --gray: #788097;
  --Beige: #f5f5dc;
  --GhostWhite: #f8f8ffx`;
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --Blue: #0c375b;
  --SkyBlue: #b0f4f4;
  --LightBlue: #4076a3;
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.body {
  overflow-x: hidden !important;
  overflow-y: visible !important;
}

.App {
  padding: 0;
  overflow-x: hidden;
  color: var(--black);
}

.rubik {
  font-family: "Rubik", sans-serif;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.Arial {
  font-family: Arial, Helvetica, sans-serif;
}
.text-outlineB {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.text-outlineW {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.txt-blue {
  color: #0c375b;
}
.bg-baseblue {
  background: #0c375b;
}
.txt-brightblue {
  color: #073f6d;
}

.txt-pink {
  color: #ff00bf;
}
.bg-pink {
  background-color: #df6891;
}
.bg-blue {
  background-color: #beabfb;
}
.bg-yellow {
  background-color: #f3d35b;
}
.bg-purple {
  background-color: #df8ce0;
}

.vh-85 {
  height: 85vh;
}
.bg-blueparent {
  position: relative;
}

.bg-blueimage {
  background: url("./Images/Blue_gradient_Top_banner_cropped.png") no-repeat;
  background-color: #f1f3f4;
  position: absolute;
  background-position-x: right;
  background-position-y: top;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}
.bg-mobblueimage {
  background: url("./Images/Blue_gradient_Top_banner_cropped.png") no-repeat;
  background-color: #f1f3f4;
  position: absolute;
  overflow: hidden;
  height: 85vh;
  width: 100%;
}
.bg-itblueimage {
  background: #101e37;
  background-image: linear-gradient(to bottom, #101e37, #5e7bc5);
  border-bottom-left-radius: 220px;
  /* background: url("./Images/gradblueitbg.png") no-repeat; */
  overflow: hidden;
  background-size: contain;
  height: 100vh;
  width: 100%;
}
.homebanner2 {
  background: url("./Images/Home2\ banner-01.png") no-repeat;
  position: absolute;
  background-size: cover;
  background-position: center;
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
}
.homebanner3 {
  background: url("./Images/Home3\ Banner-01.png") no-repeat;
  position: absolute;
  background-size: cover;
  background-position: center;
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
}

.bg-blueimagemain {
  background: url("./Images/Banner\ BG1-01.png") no-repeat;
  background-color: #dfe0e2;
  background-position-x: right;
  background-size: contain;
  position: absolute;
  height: 100vh;
  width: 100%;
}

.wings {
  background: url("./Images/Asset\ 2.png") no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
  position: absolute;
  top: -120px;
  left: 5%;
  height: 600px;
  width: 630px;
  z-index: 1;
}
.bulb {
  background: url("./Images/Bulb_home2_banner_img.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: -120px;
  left: 5%;
  margin-left: 10%;
  height: 75vh;
  width: 580px;
  z-index: 1;
}
.cube {
  background: url("./Images/Cube\ for\ banner@216x.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: -120px;
  left: 5%;
  height: 75vh;
  width: 580px;
  z-index: 1;
}

.orange-circle {
  background: url("./Images/lightRed_halfCirlce_gradient_banner_cropped.png")
    no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  height: 160px;
  width: 160px;
  right: 660px;
  top: 190px;
}
.blue-circle {
  background: url("./Images/Blue_gradient_circle_Banner.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  height: 130px;
  width: 130px;
  bottom: 86%;
  left: 55%;
}
.blue-half-circle {
  background: url("./Images/Blue_gradient_circle_Banner_half.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  height: 140px;
  width: 70px;
  bottom: 58%;
  right: -3%;
}
.red-circle-bpiece {
  background: url("./Images/image.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  height: 100%;
  width: 175px;
  left: 79%;
  top: 53%;
}
.red-circle-spiece {
  background: url("./Images/imagesmall.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  height: 100%;
  width: 95px;
  left: 66%;
  top: 72%;
}

.main-page-text {
  position: absolute;
  top: 102%;
  left: 26%;
}

.blue-across-bg {
  background: url("./Images/Blue_gradient_cross.png") no-repeat;
  background-position: center;
  background-size: cover;
  height: 45rem;
  max-width: 100%;
}

.main-neuron-filled {
  background: url("./Images/Gray_Neurons_pattern.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 86%;
  left: -5%;
  width: 350px;
  height: 400px;
}
.main-neuron-empty {
  background: url("./Images/Line_neuron_pattern.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0;
  right: 0%;
  /* right: -5%; */
  width: 400px;
  height: 500px;
}

.p-text-left {
  left: 55%;
  width: 31%;
  margin-top: 20px;
}

.cardBorder {
  border: 1px solid rgb(15, 15, 15);
}
.wcardBorder {
  border: 1px solid rgb(255, 255, 255);
}
.gcardBorder {
  border: 1px solid #7a7a7a;
}

.textOurservices {
  font-size: 5.5rem;
  line-height: 1;
}

.borderRightCurve {
  -webkit-border-top-right-radius: 75px;
  -moz-border-radius-topright: 75px;
  border-top-right-radius: 75px;
}
.footerneuron {
  background: url("./Images/Line_neuron_pattern.png") no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: -40px;
  z-index: 1;
  width: 400px;
  height: 400px;
  opacity: 50%;
}

.footerbg {
  background-image: linear-gradient(to right, #195688, #061f33);
}
.traveltech {
  background-image: linear-gradient(to bottom left, #275478, #03121f);
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 1150px;
}
.cut-background {
  position: relative; /* Required for positioning the pseudo-element */
  overflow: hidden; /* Hides the content outside the element */
}
.cut-background::before {
  content: "";
  position: absolute;
  bottom: -150px;
  left: 0;
  width: 100%;
  height: 40%; /* Adjust the height to control the slope size */
  background-color: #fff; /* Replace #fff with the background color you want for the slope */
  transform: skewY(7deg); /* Adjust the angle of the slope */
}

.projectsimg {
  background: url("./Images/carouselmain.png") no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 1100px;
}

.ERPimg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./Images/Finance.jpg") no-repeat;
  background-position: top;
  background-size: cover;
  transition: 0.5s;
}
.ERPimg:hover {
  transform: scale(1.04);
}

.airbookingimg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./Images/air-booking.jpg") no-repeat;
  background-position: top;
  background-size: cover;
  transition: 0.5s;
}
.airbookingimg:hover {
  transform: scale(1.04);
}

.mobileimg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./Images/mobimg.png") no-repeat;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
}
.mobileimg:hover {
  transform: scaley(1.03);
}

.scalehover:hover {
  transform: scale(1.05);
}
.phover:hover {
  background-color: #df6891;
  transform: scale(1.05);
}
.bhover:hover {
  background-color: #beabfb;
  transform: scale(1.05);
}

.travelbhover:hover {
  background-color: #db2777;
}

.travelthover:hover {
  background-color: #0d9488;
}
.travelghover:hover {
  background-color: #94a3b8;
}

.yhover:hover {
  background-color: #f3d35b;
  transform: scale(1.05);
}
.puhover:hover {
  background-color: #df8ce0;
  transform: scale(1.05);
}

.h1000 {
  height: 1100px;
}

.ml-35 {
  margin-left: 35%;
}

/* about us css */

.bgaboutus {
  background: url("./Images/wavyblue.png") no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.boxemployees {
  background: url("./Images/AboutusBannerImg.png") no-repeat;
  background-position: bottom;
  background-size: contain;
  height: 83vh;
  margin-right: 3%;
  margin-top: 6%;
}

.aboutusneuron {
  background: url("./Images/Gray_Neurons_pattern.png") no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 50%;
  position: absolute;
  height: 700px;
  width: 100%;
  left: -70%;
  bottom: 0%;
}

.diagonalsquare {
  background: url("./Images/diagonalsquare.png") no-repeat;
  background-position: center;
  background-size: contain;
  height: 500px;
}

.aboutmeetimg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.753)),
    url("./Images/Aboutus_Our_team_.png") no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;
  width: 100%;
}

/* IT consulting css */

.itimage1 {
  background: url("./Images/itimage1.png") no-repeat;
  background-position: center;
  background-size: contain;
  height: 96vh;
  width: 100%;
  position: absolute;
  right: 10%;
  margin-top: 2%;
  transform: rotate(12deg);
  margin-right: 20px;
}
.itimage2 {
  background: url("./Images/itimage2.png") no-repeat;
  border-bottom-left-radius: 30px;
  background-position-x: left;
  background-position-y: center;
  transform: rotate(-7deg);
  margin-left: 4%;
  margin-top: 1%;
  background-size: contain;
  height: 98vh;
  width: 100%;
  position: absolute;
  left: 10%;
  overflow-x: hidden;
}
.itimage3 {
  background: url("./Images/itimage3.png") no-repeat;
  background-position: center;
  background-size: contain;
  height: 98vh;
  margin-top: 1%;
  transform: rotate(9deg);
  margin-right: 4%;
  width: 100%;
  position: absolute;
  right: 10%;
}
.itimage4 {
  background: url("./Images/itimage4.png") no-repeat;
  border-bottom-left-radius: 30px;
  background-position-x: left;
  background-position-y: center;
  transform: rotate(-5deg);
  margin-left: 20px;
  background-size: contain;
  height: 98vh;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  left: 10%;
}
.itimage5 {
  background: url("./Images/itimage5.png") no-repeat;
  background-position: center;
  background-size: contain;
  height: 98vh;
  margin-top: 6px;
  transform: rotate(12deg);
  margin-right: 20px;
  width: 100%;
  position: absolute;
  right: 10%;
}
.itimage6 {
  background: url("./Images/itimage7.png") no-repeat;
  border-bottom-left-radius: 30px;
  background-position-x: left;
  background-position-y: center;
  transform: rotate(-7deg);
  margin-left: 20px;
  background-size: contain;
  height: 98vh;
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  left: 10%;
}
.itimage7 {
  background: url("./Images/itimage8.png") no-repeat;
  background-position: center;
  background-size: contain;
  height: 98vh;
  margin-top: 6px;
  transform: rotate(12deg);
  margin-right: 20px;
  width: 100%;
  position: absolute;
  right: 10%;
}

.bleft {
  border-bottom-left-radius: 320px;
}
.bright {
  border-bottom-right-radius: 320px;
}

/* our work css */

.yellowneuron {
  background: url("./Images/Collapsable\ Menu_neurons_image.png") no-repeat;
  background-position: right;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 45vh;
  top: -9%;
  right: 10%;
  transform: rotate(135deg);
}

.carouselh {
  width: 100%;
  height: 450px;
}

.ourwork .carousel-control-prev-icon,
.carousel-control-next-icon {
  margin-top: 50%;
}

.consultinglandingpic {
  background: url("./Images/ConsultingBannerImage.png") no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
  position: absolute;
  top: -140px;
  left: 9%;
  height: 540px;
  width: 570px;
  z-index: 1;
}
.Itsupportbg {
  background: url("./Images/SupportBannerImage.png") no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
  position: absolute;
  top: -140px;
  left: 9%;
  height: 640px;
  width: 670px;
  z-index: 1;
}
.Itsolutionbg {
  background: url("./Images/SolutionsBannerImage.png") no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
  position: absolute;
  top: -140px;
  left: 9%;
  height: 640px;
  width: 670px;
  z-index: 1;
}
.Itservicebg {
  background: url("./Images/ServicesBannerImage.png") no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
  position: absolute;
  top: -140px;
  left: 9%;
  height: 640px;
  width: 670px;
  z-index: 1;
}
.Itcareerbg {
  background: url("./Images/CareersBannerImage.png") no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
  position: absolute;
  top: -140px;
  left: 9%;
  height: 640px;
  width: 670px;
  z-index: 1;
}
.careerbgimg {
  background: url("./Images/careerbgimg.png") no-repeat;
  background-color: #eef2f9 !important;
  background-size: contain;
  height: 740px;
  width: 100%;
}
.carrierlogo {
  height: 130px !important;
  width: 420px !important;
}
.carrierbgimgcolor {
  background-color: #eef2f9 !important;
}
.carrierbgheight {
  height: 950px !important;
}

@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;
  }
  .h-400 {
    height: 400px;
  }

  .p-text-left {
    left: 0% !important;
    width: 100% !important;
    color: rgb(77, 77, 77) !important;
    padding: 5px;
  }
  .heightcontainer {
    height: 700px !important;
    overflow-y: auto;
  }
  .wings {
    background-position-x: right;
    background-position-y: top;
    background-size: contain;
    position: absolute;
    top: 140px;
    left: -5%;
    height: 220px;
    width: 300px;
    z-index: 1;
  }

  .blue-circle {
    top: 180%;
    left: 67%;
  }

  .main-page-text {
    top: 150%;
    left: 5%;
  }
  .textOurservices {
    font-size: 3.5rem;
    line-height: 1;
  }

  .navbar-toggler {
    border: 2px solid black;
    margin-top: 10px !important;
  }
  .toggler-icon {
    background: black;
  }
  .consultinglandingpic {
    top: 125px;
    left: -17%;
    height: 300px;
    width: 333px;
  }
  .Itsupportbg {
    top: 125px;
    left: -17%;
    height: 300px;
    width: 333px;
  }
  .Itsolutionbg {
    top: 125px;
    left: -17%;
    height: 300px;
    width: 333px;
  }
  .Itservicebg {
    top: 125px;
    left: -17%;
    height: 300px;
    width: 333px;
  }
  .Itcareerbg {
    top: 125px;
    left: -17%;
    height: 300px;
    width: 333px;
  }
  .boxemployees {
    height: 38vh;
    width: 95%;
    margin-right: 3%;
    margin-top: 0%;
  }
  .bgaboutus {
    width: 100%;
    height: 80vh;
  }
  .ml-35 {
    margin-left: 4%;
  }
  .h1000 {
    height: auto;
  }
  .carrierbgheight {
    height: auto !important;
  }
  .careerbgimg {
    height: auto;
  }
  .ourwork .boxemployees {
    height: 34vh;
  }
  .bleft {
    border-bottom-left-radius: 0px !important;
  }
  .bright {
    border-bottom-right-radius: 0px !important;
  }
  .itimage1 {
    height: 25vh;
  }
  .itimage2 {
    height: 25vh;
  }
  .itimage3 {
    height: 25vh;
  }
  .itimage4 {
    height: 25vh;
  }
  .itimage5 {
    height: 25vh;
  }
  .itimage6 {
    height: 25vh;
  }
  .itimage7 {
    height: 25vh;
  }
  .traveltech {
    height: 2600px;
  }
  .Traveltechnology .relative-edit {
    bottom: 830px !important;
  }
  .cut-background::before {
    display: none;
  }
  .right60 {
    right: 0% !important;
  }
  .top23 {
    top: 0% !important;
  }
  .rotate-90 {
    transform: rotate(0deg) !important;
  }
  .underline-offset-15 {
    text-underline-offset: 4px !important;
  }
  .bgtravellast {
    height: 400px !important;
  }
  .bgtravellastright {
    height: 400px !important;
  }
}
@media (min-width: 481px) and (max-width: 900px) {
  .traveltech {
    height: 2300px;
  }
  .Traveltechnology .relative-edit {
    bottom: 650px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .blue-half-circle {
    right: 0%;
  }

  .red-circle-bpiece {
    left: 77%;
    top: 68%;
  }
  .red-circle-spiece {
    left: 65%;
    top: 87%;
  }
  .orange-circle {
    right: 680px;
  }
  .main-page-text {
    top: 110%;
  }
  .top23 {
    top: 44% !important;
  }
  .right60 {
    right: 62% !important;
  }
}
@media (min-width: 1367px) and (max-width: 1599px) {
  body {
    overflow-x: hidden;
  }
  .bg-blueimage {
    height: 100vh;
    background-size: contain;
  }

  .wings {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .consultinglandingpic {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itsupportbg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itsolutionbg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itservicebg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itcareerbg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }

  .orange-circle {
    right: 92%;
    top: 52%;
  }

  .blue-half-circle {
    top: 30%;
    right: 0%;
  }
  .main-page-text {
    top: 87%;
    left: 24%;
    font-size: 2rem;
  }
  .red-circle-bpiece {
    left: 79%;
    top: 49%;
  }
  .red-circle-spiece {
    top: 61%;
    left: 69%;
  }
  .main-neuron-empty {
    height: 490px;
  }
  .textOurservices {
    font-size: 7rem;
  }
  .top23 {
    top: 44% !important;
  }
  .right60 {
    right: 62% !important;
  }
}
@media (min-width: 1600px) and (max-width: 1799px) {
  body {
    overflow-x: hidden !important;
  }
  .bg-blueimage {
    height: 100vh;
    background-size: contain;
  }

  .wings {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .consultinglandingpic {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itsupportbg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itsolutionbg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itservicebg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }
  .Itcareerbg {
    background-size: cover;
    height: 500px;
    width: 730px;
  }

  .orange-circle {
    right: 89%;
    top: 52%;
  }

  .blue-half-circle {
    top: 30%;
    right: 0%;
  }
  .main-page-text {
    top: 87%;
    left: 24%;
    font-size: 2rem;
  }
  .red-circle-bpiece {
    left: 79%;
    top: 51%;
  }
  .red-circle-spiece {
    top: 63%;
    left: 69%;
  }
  .main-neuron-empty {
    height: 490px;
  }
  .textOurservices {
    font-size: 7rem;
  }
}
@media (min-width: 1800px) and (max-width: 1920px) {
  /* @media screen and (max-width: 1920px) { */
  body {
    overflow-x: hidden;
  }
  .bg-blueimage {
    height: 100vh;
    background-size: contain;
  }
  .wings {
    width: 100%;
    left: -8%;
    top: -5%;
  }
  .consultinglandingpic {
    width: 100% !important;
    height: 70vh !important;
    left: -20% !important;
    top: -25% !important;
  }
  .Itsupportbg {
    width: 100% !important;
    height: 70vh !important;
    left: -20% !important;
    top: -25% !important;
  }
  .Itsolutionbg {
    width: 100% !important;
    height: 70vh !important;
    left: -20% !important;
    top: -25% !important;
  }
  .Itservicebg {
    width: 100% !important;
    height: 70vh !important;
    left: -20% !important;
    top: -25% !important;
  }
  .Itcareerbg {
    width: 100% !important;
    height: 70vh !important;
    left: -20% !important;
    top: -25% !important;
  }

  .orange-circle {
    right: 85%;
    top: 75%;
    background-size: cover;
    height: 190px;
    width: 250px;
  }
  .blue-circle {
    left: 60%;
    top: 2%;
    background-size: cover;
    height: 200px;
    width: 200px;
  }
  .blue-half-circle {
    top: 53%;
    right: 0%;
    background-size: cover;
    height: 200px;
    width: 100px;
  }
  .red-circle-bpiece {
    left: 81%;
    top: 113%;
    background-size: cover;
    height: 230px;
    width: 200px;
  }
  .red-circle-spiece {
    top: 140%;
    left: 71%;
    background-size: cover;
    height: 100px;
    width: 110px;
  }

  .main-page-text {
    top: 128%;
    left: 28%;
    font-size: 2.5rem;
  }

  .blue-across-bg {
    background-position: inherit;
  }

  .tw-container.borderRightCurve {
    max-width: 1904px;
    height: 600px;
  }

  .main-neuron-empty {
    top: 9%;
  }
  .tw-container.carouselContainer {
    max-width: 1904px;
  }
  .textOurservices {
    font-size: 8rem;
  }

  .p-text-left {
    left: 57%;
    font-size: 20px;
    width: 40%;
  }

  .main-neuron-empty {
    height: 440px;
  }
}

/* travel technology css */

.css-1hu2bw9-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid white !important;
}

.css-1hu2bw9-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid white !important;
}

.ttform svg path {
  color: white;
}

.Traveltechnology .relative-edit {
  bottom: 300px;
}

.right60 {
  right: 60%;
}
.top23 {
  top: 23%;
}
.rotate-90 {
  transform: rotate(-90deg);
}
.underline-offset-15 {
  text-underline-offset: 15px;
}

.rotate-text {
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  white-space: nowrap;
}

.rotate-45 {
  transform: rotate(-45deg);
}
.rounded-br-150 {
  border-bottom-right-radius: 150px;
}

.iconzize {
  font-size: 140px;
}

.bgtravelpurple {
  background-color: #7f89a8;
}

.brushking {
  font-family: "BrushKing", sans-serif;
}
.desfont {
  font-family: cursive, "Pacifico", cursive, sans-serif;
}

.bgtraveldesktop {
  background: url("./Images/ComputerWithImage\ \(1\).png") no-repeat;
  background-position: center;
  background-size: contain;
  height: auto;
}
.bgtraveldesktopandmobile {
  background: url("./Images/ComputerWithImage\ \(3\).png") no-repeat;
  background-position: center;
  background-size: contain;
  height: auto;
}
.bg-travel-map {
  background: url("./Images/ComputerWithImage\ \(6\).png") no-repeat;
  background-position: center;
  background-size: contain;
  height: auto;
}
.bg-travel-lane {
  background: url("./Images/ComputerWithImage\ \(4\).png") no-repeat;
  background-position: center;
  background-size: contain;
}

.bgtraveldarkpurple {
  background: url("./Images/ComputerWithImage\ \(7\).png") #421035 no-repeat;
  background-position: right;
  background-size: contain;
}
.bgtravellast {
  background: url("./Images/travellast.png") #7f89a8 no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 90vh;
}
.bgtravellastright {
  background: url("./Images/travellastright.png") #7f89a8 no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 90vh;
}
.bg-travel-plane {
  background: url("./Images/ComputerWithImage\ \(2\).png") no-repeat;
  background-size: contain;
  width: 100%;
}
.bg-travel-hotel {
  background: url("./Images/ComputerWithImage\ \(5\).png") no-repeat;
  background-size: contain;
  width: 100%;
}

.Traveltechnology .h-10 {
  height: 10%;
}
.Traveltechnology .h-80 {
  height: 80%;
}
.w-travel-700 {
  min-width: 650px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}
.scroll-travel::-webkit-scrollbar {
  display: none;
}
.r-travel-12 {
  right: 12%;
}
